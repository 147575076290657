
.videoHeatBox{
    display: flex;
    flex-direction: column;
    height: 100%;
    .videoHeatHeader{
        border-bottom: 1px solid #EAEAEA;
        margin-left: 25px;
        display: flex;
        .headerImg{
            width: 110px;
            height: 158px;
            margin-bottom: 24px;
        }
        .headerCountent{
            margin-left: 23px;
            p{
                line-height: 1;
                margin: 0;
            }
            p:nth-child(1){
                a{
                    color: #333333;
                    font-size: 18px;
                    font-weight: bold;
                }
                margin-top: 10px;
            }
            p:nth-child(2){
                margin-top: 23px;
                span:nth-child(1){
                    font-size: 32px;
                    color: #FF0000;
                    b{
                        font-size: 16px;
                    }
                }
                span:nth-child(2){
                    margin-left: 21px;
                    font-size: 16px;
                    color: #666666;
                    text-decoration:line-through;
                }
            }
            p:nth-child(3){
                margin-top: 23px;
                font-size: 14px;
                color: #999999;
                span:nth-child(2){
                    margin-left: 24px;
                }
            }
        }
    }
    .main_box {
        padding-top: 20px;
        margin-left: 40px;

        .left-item-fan {
            margin-bottom: 20px;

            .item-half-sub {
                font-size: 16px;
                line-height: 24px;
            }

            .left-item-content {
                background: #fff;
                margin-top: 15px;
                padding: 15px;

                .fan-tab {
                    text-align: right;
                    padding-right: 30px;
                    color: #999;
                    line-height: 1;

                    .item {
                        display: inline-block;
                        padding: 0 10px;
                        border-right: 1px solid #999;
                        cursor: pointer;

                        &:hover.item {
                            color: #20C997;
                        }

                        &:current {
                            color: #20C997;
                        }

                        &:last-of-type {
                            border-right: 0;
                            padding: 0 0 0 10px;
                        }
                    }
                }
            }
        }
    }
    .current {
        color: #20C997;
    }

}
