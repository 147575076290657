
.goodDetail-right {
    width: 360px;
    background: #fff;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    /*min-height: calc(100vh - 100px);*/
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .header {
        padding: 20px 20px 0;
        text-align: center;
        font-size: 16px;
    }
    .help-list {
        height: 1%;
        flex: 1;
        margin: 20px 0;
        .h-item {
            display: flex;
            padding: 0 20px;
            margin-top: 10px;
            color: #333;
            cursor: pointer;
            &:first-child {
                margin-top: 0;
            }
            .index {
                min-width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                color: #fff;
                background: #0000FF;
                text-align: center;
                border-radius: 50%;
            }
            .title {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                margin-top: 0;
                margin-bottom: 0;
            }
            &:hover {
                color: #1E33E3;
            }
        }
    }
}
