
.videoHeatBox{
    display: flex;
    flex-direction: column;
    height: 100%;
    /*overflow: scroll;*/
    .videoHeatHeader{
        border-bottom: 1px solid #EAEAEA;
        margin-left: 25px;
        display: flex;
        .headerImg{
            width: 110px;
            height: 158px;
            margin-bottom: 24px;
        }
        .headerCountent{
            margin-left: 23px;
            p{
                line-height: 1;
                margin: 0;
            }
            p:nth-child(1){
                a{
                    color: #333333;
                    font-size: 18px;
                    font-weight: bold;
                }
                margin-top: 10px;
            }
            p:nth-child(2){
                margin-top: 23px;
                span:nth-child(1){
                    font-size: 32px;
                    color: #FF0000;
                    b{
                        font-size: 16px;
                    }
                }
                span:nth-child(2){
                    margin-left: 21px;
                    font-size: 16px;
                    color: #666666;
                    text-decoration:line-through;
                }
            }
            p:nth-child(3){
                margin-top: 23px;
                font-size: 14px;
                color: #999999;
                span:nth-child(2){
                    margin-left: 24px;
                }
            }
        }
    }
    .correlationVideo{
        height: 65px;
        margin-left: 25px;
        border-bottom: 1px solid #EAEAEA;
        line-height: 65px;
        color: #333333;
        font-size: 16px;
    }
    .rongQi{
        .correlationVideoItem{
            position: relative;
            margin-left: 25px;
            border-bottom: 1px solid #EAEAEA;
            display: flex;
            .correlationImg{
                width: 60px;
                height: 88px;
                margin-top: 18px;
                margin-bottom: 16px;
            }
            .correlationTitle{
                width: 432px;
                padding-top: 38px;
                margin-left: 24px;
                p{
                    color: #333333;
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;

                }
            }
            .correlationUser{
                display: flex;
                margin-left: 24px;
                margin-top: 46px;
                div{
                    margin-left: 10px;
                    p{
                        line-height: 1;
                        margin: 0;
                    }
                    p:nth-child(2){
                        color: #999999;
                        margin-top: 6px;
                    }
                }
            }
            .correlationRight{
                /*margin-left: 9%;*/
                position: absolute;
                left: 76%;
                top: 44%;
                display: flex;
                align-items: center;

                line-height: 100%;
                i{
                    font-size: 16px;
                    color: #999999;
                }
                span{
                    font-size: 16px;
                    color: #999999;
                    margin-left: 8px;
                }
            }
        }
    }
}
