
.videoAnalyze{
    height: 100%;
    display: flex;
    justify-content: space-between;
    .left_box {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        border-right: 1px solid #eaeaea;
        padding: 0 20px 20% 20px;

        .gender-box {
            display: flex;
            flex-direction: column;
            margin-bottom: 68px;

            .gender-chart {
                margin-top: 24px;
                .icon-box {
                    display: flex;
                    justify-content: space-between;
                    color: #34aa7f;
                }

                .chart-content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    height: 16px;
                    margin-top: 20px;
                    .man {
                        background-color: #34aa7f;
                    }

                    .women {
                        flex: 1;
                        background-color: #46dbab;
                    }
                }

                .bottom-box {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    .box1 {
                        display: flex;
                        flex-direction: column;
                    }
                    .box1:nth-child(1){
                        align-items: end;
                        span:nth-child(2){
                            margin-top: 8px;
                        }
                    }
                    .box1:nth-child(2){
                        align-items: flex-end;
                        span:nth-child(2){
                            margin-top: 8px;
                        }
                    }
                }
            }
        }

        .age-distribution {
            width: 100%;
            .title {
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
        }
    }
    .right_box {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        padding: 0 20px;
        overflow: auto;
        .area-distribution {
            flex: 1;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            height: calc(100% - 66px);

            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333;
            }

            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
                .area-item:nth-child(1){
                    margin-top: 20px;
                }
            }

            .area-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 35px;

                ::v-deep .el-progress {
                    width: 80%;
                    padding: 0 10px;
                }

                ::v-deep .el-progress-bar__inner {
                    background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                }
            }
        }
    }
}
