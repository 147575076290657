
.videoDetailBox{
    /*overflow: scroll;*/
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .videoDetailHeader{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .DetailHeaderLeft{
            margin-left: 35px;
            display: flex;
            .headImg{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
            .headerIdFs{
                p{
                    margin: 0;
                    margin-left: 16px;
                }
                p:nth-child(1){
                    color: #333333;
                    font-size: 16px;
                }
                p:nth-child(2){
                    color: #333333;
                    font-size: 14px;
                }
            }
        }
        .DetailHeaderright{
            display: flex;
            align-items: center;
            text-align: center;
            .xian{
                width: 1px;
                height: 30px;
                background: #EAEAEA;
            }
            div{
                margin-right: 40px;
                p{
                    line-height: 1;
                    margin: 0;
                }
                p:nth-child(2){
                    margin-top: 8px;
                }
            }
        }
    }
    .DetailComment{
        margin-top: 62px;
        .DetailCommentTile{
            span:nth-child(1){
                color: #333333;
                font-size: 16px;
                margin-left: 32px;
            }
            span:nth-child(2){
                color: #999999;
                font-size: 16px;
            }
        }
    }
    .DetailCommentList{
        margin-top: 10px;
        margin-left: 40px;
        .DetailCommentItem{
            position: relative;
            margin-right: 26px;
            border-bottom: 1px solid #EAEAEA;
            display: flex;
            justify-content: space-between;
            div{
                i{
                    color: #999999;
                    font-size: 17px;
                }
                p:nth-child(1){
                    color: #333333;
                    font-size: 14px;
                    line-height: 1;
                    margin: 0;
                    margin-top: 14px;
                }
                p:nth-child(2){
                    color: #999999;
                    font-size: 14px;
                    line-height: 1;
                    margin: 0;
                    margin-top: 11px;
                }
            }
            div:nth-child(2){
                position: absolute;
                left: 90%;
                top: 40%;
            }
        }
    }
}
